import React from "react"
import {Link,useStaticQuery} from "gatsby"


const InfoMenuItem = (props) => {
  const {title,to,level,active} = props;



  return (
    <div data-isactive={active} className={"info_menu_item"}>
      <Link data-level={level} key={title} activeClassName="active" className="service_menu_item" to={to}>{title}</Link>
    </div>
  )
}


const InfoMenu = (props) => {

  const {activePath} = props;

  const data = useStaticQuery(graphql`
    {
      allWordpressWpInfomenu(sort: {fields: menu_order, order: ASC}) {
        edges {
          node {
            post_name
            post_parent
            menu_order
            menu_item_parent
            title
            wordpress_id
            object_id
          }
        }
      }
      allWordpressPage {
        edges {
          node {
            title
            path
            slug
            wordpress_id
          }
        }
      }
    }
  `)

  const pages = data.allWordpressPage.edges.map((e) => e.node);
  const pointswp = data.allWordpressWpInfomenu.edges.map(({node}) => {
    let page = pages.find(p => p.wordpress_id === parseInt(node.object_id));

    return {
      t: node.title,
      a: page.path,
    };
  });

  return (
    <div className={"info_menu"}>
      {
        pointswp.map((p) => {
          return (
           <InfoMenuItem active={activePath === p.a} key={p.a} level={0} title={p.t} to={p.a} children={p.children}/>
          )
        })
      }
    </div>
  )
}

export default InfoMenu;
