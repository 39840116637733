import React from "react"
import BaseLayout from "../layout/BaseLayout"
import Menu from "../components/infoMenu"

const Paragraphs = (props) => {

  const { paragraphs } = props
  const sections = paragraphs.map(p => {

    if (p.type === "md") {
      return <div className={"para para_md"} dangerouslySetInnerHTML={{ __html: p.val }}/>
    }

    if (p.type === "image_and_text") {

      let { v_md, v_alignment } = JSON.parse(p.val)
      let imagesrc = p.v_image?.localFile?.childImageSharp?.resize?.src

      return (
        <div className={"para para_image_and_text " + "alignment-" + v_alignment}>
          <div className={"para_image_and_text_img"}>
            <img src={imagesrc}/>
          </div>
          <div className={"para_image_and_text_md"} dangerouslySetInnerHTML={{ __html: v_md }}/>
        </div>
      )
    }
  })

  return (
    <div className="paragraphs">
      {sections}
    </div>
  )
}


const WPPage = (props) => {
  const { pageContext } = props
  const node = pageContext.node
  const { title } = node


  if (node._fx_post_type === "info") {
    return (
      <BaseLayout context={pageContext}>
        <div className="content_inner content_padding_top">
          <div className="info_menu_wrap content_centered">
            {node._fx_post_type === "info" && (
              <Menu activePath={pageContext.slug}/>
            )}
          </div>
          <div className="title_wrap content_centered">
            <h1>{title}</h1>
          </div>
          <Paragraphs paragraphs={node.paragraphs}/>
        </div>
      </BaseLayout>
    )
  } else {
    return (
      <BaseLayout context={pageContext}>
        <div className="content_inner content_padding_top">
          <div className="title_wrap content_centered">
            <h1>{title}</h1>
          </div>
          <Paragraphs paragraphs={node.paragraphs}/>
        </div>
      </BaseLayout>
    )
  }


}

export default WPPage
